import updateNestedValue from '@assets/helpers/updateNestedValue';
import {Button, Layout, Stack} from '@shopify/polaris';
import React, {useEffect, useState} from 'react';

export default function AutoCompleteCustom({
  title = '',
  data = [],
  onChange = () => {},
  disabled = false,
  HtmlContent = null,
  defaultValue = [{}],
  actionTitle = 'Add new',
  actions = null,
  emptyState = null
}) {
  const [options, setOptions] = useState(data || []);
  const handleAddNewOption = (val = defaultValue) => {
    setOptions(prev => [...prev, ...val]);
  };
  const handleOptionChange = (index, key, value) =>
    setOptions(prev =>
      prev.map((option, i) => (i === index ? updateNestedValue(option, key, value) : option))
    );

  const handleRemoveOption = index => {
    setOptions(prev => prev.filter((a, i) => i !== index));
  };

  useEffect(() => {
    setOptions(data);
  }, [data]);

  useEffect(() => {
    onChange(options);
  }, [options]);

  useEffect(() => {
    if (data.length && !options.length) setOptions(data);
  }, [data]);

  return (
    <div>
      {title}
      {options.length ? (
        <HtmlContent
          {...{
            onChange: (index, key, value) => handleOptionChange(index, key, value),
            onRemove: index => handleRemoveOption(index),
            options
          }}
        />
      ) : (
        emptyState
      )}
      {!disabled && (
        <div style={{padding: '1rem'}}>
          {!!actions ? (
            actions({onAdd: val => handleAddNewOption(val), disabled})
          ) : (
            <Stack>
              <Button disabled={disabled} onClick={() => handleAddNewOption()}>
                {actionTitle}
              </Button>
            </Stack>
          )}
        </div>
      )}
    </div>
  );
}
