import Http from '@assets/services/Http';
import {keepPreviousData, useQuery, useQueryClient, useSuspenseQuery} from '@tanstack/react-query';

const fetcher = async ({url, method = 'GET', payload = {}, params, onSuccess}) => {
  const response = await Http({
    url: url,
    method,
    params,
    data: payload
  });

  onSuccess(response);
  return response;
};

const useHttpReq = ({
  queryKey,
  requestOptions: {url, method = 'GET', payload = {}, params, ...queryOpts} = {},
  useSuspense = false,
  onSuccess = data => {},
  presentData = null
}) => {
  const queryClient = useQueryClient();
  const {data, ...dataQuery} = (useSuspense ? useSuspenseQuery : useQuery)({
    queryKey,
    queryFn: () => fetcher({url, method, payload, params, onSuccess}),
    retryOnMount: false,
    refetchInterval: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchIntervalInBackground: false,
    staleTime: Infinity,
    ...(queryOpts?.keepPreviousData ? {placeholderData: keepPreviousData} : {}),
    ...queryOpts
  });

  function setState(newData, mergeKey = true) {
    queryClient.setQueryData(queryKey, prev => {
      const updatedData = typeof newData === 'function' ? newData(prev) : newData;
      return mergeKey ? {...prev, data: {...prev.data, ...updatedData}} : updatedData;
    });
  }

  function invalidateQueries({queryKeys = [], validateCurrent = true} = {}) {
    const keyCanInvalidate = validateCurrent ? [queryKey, ...queryKeys] : queryKeys;

    keyCanInvalidate.forEach(queryKey => {
      queryClient.refetchQueries({queryKey});
    });
  }

  function clearAllCache() {
    queryClient.clear();
    queryClient.removeQueries();
  }

  return {
    ...dataQuery,
    data: presentData ? presentData(data) || data : data,
    setState,
    invalidateQueries,
    clearAllCache
  };
};

const useInvalidateCache = () => {
  const queryClient = useQueryClient();

  async function invalidateCache({queryKeys}) {
    for (const queryKey of queryKeys) {
      await queryClient.refetchQueries({queryKey});
    }
  }

  return invalidateCache;
};

const checkQueryKeyExists = queryKey => {
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData(queryKey);
  return data !== undefined;
};

const useGetData = queryKey => {
  const queryClient = useQueryClient();
  return queryClient.getQueryData(queryKey);
};

export {checkQueryKeyExists, useGetData, useHttpReq, useInvalidateCache};
