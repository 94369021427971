import {checkPermission} from '@assets/helpers/user/permission';
import CreatePassword from '@assets/loadables/Auth/CreatePassword';
import ForgotPassword from '@assets/loadables/Auth/ForgotPassword';
import Login from '@assets/loadables/Auth/Login';
import importExportHistory from '@assets/routes/importExportHistory';
import settingsRoutes from '@assets/routes/settingsRoutes';
import storeRoutes from '@assets/routes/storeRoutes';
import {Card, SkeletonBodyText, SkeletonPage} from '@shopify/polaris';
import {Suspense} from 'react';
import {connect} from 'react-redux';
import {Redirect, Route, Switch} from 'react-router-dom';
import Home from '../loadables/Home';
import orderRoutes from './orderRoutes';
import productRoutes from './productRoutes';
import transactionRoutes from './transactionRoutes';
import userRoutes from './userRoutes';

const Routes = ({user}) => {
  if (user.isLoggerIn) {
    <Redirect exact from="*" to="/login" />;
  }
  return (
    <Suspense
      fallback={
        <SkeletonPage fullWidth>
          <Card sectioned>
            <SkeletonBodyText lines={3} />
          </Card>
          <Card sectioned>
            <SkeletonBodyText lines={3} />
          </Card>
        </SkeletonPage>
      }
    >
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/auth/verify" component={CreatePassword} />
        <Route exact path="/auth/resetPassword" component={CreatePassword} />
        <Route exact path="/forgotpassword" component={ForgotPassword} />
        <Redirect exact from="/" to="/login" />
        {user.isLoggedIn ? (
          [
            ...userRoutes,
            ...productRoutes,
            ...settingsRoutes,
            ...orderRoutes,
            ...storeRoutes,
            ...importExportHistory,
            ...transactionRoutes,
            ...[
              {
                path: '/home',
                component: checkPermission(user, 'dashboard.view') && Home,
                scope: 'dashboard.view'
              }
            ]
          ]
            .filter(x => x.scope.split(',').some(p => checkPermission(user, p)))
            .map((props, key) => <Route key={key} exact {...props} />)
        ) : (
          <Redirect exact from="*" to="/login" />
        )}
      </Switch>
    </Suspense>
  );
};
export default connect(({user}) => ({user}), null)(Routes);
