/* eslint-disable react/jsx-key */
import {setToast} from '@assets/actions/layout/setToastAction';
import SpinnerCustom from '@assets/components/SpinnerCustom/SpinnerCustom';
import {findPOStatus, purchaseOrderTabs} from '@assets/config/purchaseOrder';
import {splitOrderId} from '@assets/helpers/order/splitOrderId';
import {formatBothDateTime} from '@assets/helpers/utils/formatFullTime';
import upperFirst from '@assets/helpers/utils/upperFirst';
import useEditApi from '@assets/hooks/api/useEditApi';
import {useHttpReq} from '@assets/hooks/api/useHttpReq';
import useConfirmModal from '@assets/hooks/modal/useConfirmModal';
import useConfirmSheet from '@assets/hooks/sheet/useConfirmSheet';
import useInput from '@assets/hooks/useInput';
import {store} from '@assets/index';
import {
  ActionList,
  Badge,
  Button,
  Card,
  Checkbox,
  DataTable,
  Filters,
  Icon,
  Page,
  Pagination,
  Popover,
  SkeletonBodyText,
  Spinner,
  Stack,
  Tabs,
  Tag,
  TextField,
  TextStyle
} from '@shopify/polaris';
import {
  ArrowRightMinor,
  DeleteMinor,
  EditMinor,
  HorizontalDotsMinor,
  MinusMinor,
  PlusMinor,
  ViewMinor
} from '@shopify/polaris-icons';
import {useCallback, useState} from 'react';
import {Col, Container, Row} from 'react-grid-system';
import Form from './Form/Form';
import {debounce} from '@assets/hooks/useDebounce';
import TextFieldMask from '@assets/components/Utils/TextFieldMask';
import {createErrorMessage, createMessage} from '@assets/const/toast/toastConst';

const OrderPurchase = () => {
  const [params, handleParamsChange, setParams] = useInput({
    stock_order: 1,
    status: 'draft',
    limit: 20,
    page: 1
  });
  const [selectedTab, setSelectedTab] = useState(0);
  const [queryValue, setQueryValue] = useState('');
  const [activeChild, setActiveChild] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [activePopover, setActivePopover] = useState(null);

  const toggleActiveChild = orderId => setActiveChild(prev => (prev === orderId ? null : orderId));
  const isActiveChild = orderId => activeChild === orderId;

  const {
    data: {data: {count: orderCounts = {}} = {}} = {},
    isFetching: baseDataLoading
  } = useHttpReq({
    queryKey: ['purchase-order/base'],
    requestOptions: {
      url: '/purchase-order/base',
      params: {
        is_purchase_order: true
      }
    },
    useSuspense: false
  });

  const {
    data: {data: orders = [], total, cur_page, per_page} = {},
    invalidateQueries,
    isFetching
  } = useHttpReq({
    queryKey: ['purchase-order', params],
    requestOptions: {
      url: '/orders/index',
      params,
      keepPreviousData: true
    }
  });

  const {handleEdit: handleDelete} = useEditApi({url: 'orders/submitDrafts'});

  const handleTabChange = useCallback(selectedTabIndex => setSelectedTab(selectedTabIndex), []);
  const handleQueryChange = useCallback(value => {
    setQueryValue(value);
    onSearchOrder(value);
  }, []);

  const onSearchOrder = useCallback(
    debounce(val => handleParamsChange('search_value', val), 700),
    []
  );

  const handleSelectAllChange = () => {
    const allItemIds = orders.flatMap(order => order.items.map(item => item.id));
    if (selectedItems.length === allItemIds.length) {
      setSelectedItems([]);
    } else {
      setSelectedItems(allItemIds);
    }
  };

  const handleOrderCheckboxChange = items => {
    const itemIds = items.map(item => item.id);

    setSelectedItems(prevSelected => {
      const areAllItemsSelected = itemIds.every(id => prevSelected.includes(id));
      if (areAllItemsSelected) {
        return prevSelected.filter(id => !itemIds.includes(id));
      } else {
        const newSelected = [...prevSelected];
        itemIds.forEach(id => {
          if (!newSelected.includes(id)) {
            newSelected.push(id);
          }
        });
        return newSelected;
      }
    });
  };

  const handleItemCheckboxChange = itemId => {
    setSelectedItems(prevSelected => {
      if (prevSelected.includes(itemId)) {
        return prevSelected.filter(id => id !== itemId);
      } else {
        return [...prevSelected, itemId];
      }
    });
  };

  const {editing: duplicating, handleEdit: handleDuplicate} = useEditApi({
    url: '/orders/actionDuplicate',
    fullResp: true
  });
  const {editing: exporting, handleEdit: handleExport} = useEditApi({
    url: '/purchase-order/export',
    fullResp: true
  });

  const {modal: duplicateModal, openModal: openDuplicateModal} = useConfirmModal({
    confirmAction: async input => {
      const currentOrder = orders.find(x => x.id === input.id);
      const item_ids = currentOrder.items.map(x => x.id);
      const {success, order_data, message} = await handleDuplicate({
        item_ids,
        order_id: input.id,
        additionalData: input.additionalData
      });
      if (!success) createErrorMessage(message);
      else createMessage('Duplicate Successfully !');
      return true;
    },
    title: `Duplicate order`,
    successCallback: () => {
      invalidateQueries({
        validateCurrent: false,
        queryKeys: [['purchase-order'], ['purchase-order/base']]
      });
      closeSheet();
      return true;
    },
    HtmlContent: ({input}) => {
      const order = orders.find(x => x.id === input.current.id);
      const originId = order.origin_id.replace(`${order.id}-`, '');

      const setNewOrderData = (id, value) => {
        input.current = {...input.current, additionalData: {[id]: value}};
      };

      return duplicating ? (
        <SpinnerCustom
          wrapperStyles={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          styles={{width: '5rem', height: '5rem'}}
        />
      ) : (
        <Stack wrap={false} alignment={'center'}>
          <Stack.Item>
            <TextStyle variation="subdued">{order.id}-</TextStyle>
            <strong style={{color: 'var(--hc-default-color  )'}}>{originId}</strong>
          </Stack.Item>
          <Stack.Item>
            <Icon source={ArrowRightMinor} />
          </Stack.Item>
          <Stack.Item fill>
            <TextFieldMask value={originId} onChange={val => setNewOrderData(order.id, val)} />
          </Stack.Item>
        </Stack>
      );
    },
    loading: duplicating
  });

  const {sheet: orderFormSheet, openSheet: openOrderSheet, closeSheet} = useConfirmSheet({
    title: ({input}) => {
      const isCreate = input.current?.type === 'create';
      if (isCreate) return 'Create purchase order';

      const orderData = orders.find(order => order.id === input.current.order?.id) || {};
      const statusData = findPOStatus(orderData.status);
      return (
        <Stack alignment="leading" spacing="loose">
          <Stack.Item>{input.current?.order?.full_origin_id}</Stack.Item>
          <Stack.Item>
            {!!statusData ? (
              <Badge status={statusData?.badge?.status}>
                {upperFirst(statusData?.content || '')}
              </Badge>
            ) : null}
          </Stack.Item>
        </Stack>
      );
    },
    actions: [
      {
        label: 'Duplicate',
        onClick: input => openDuplicateModal(input.current.order),
        isShow: input => !!input.current.order?.id && input.current.type !== 'edit'
      },
      {
        label: 'Edit',
        onClick: input => (input.current.type = 'edit'),
        isShow: input =>
          input.current.type !== 'edit' &&
          !!input.current.order?.id &&
          input.current.order?.status === 'draft'
      },
      {
        label: 'Export CSV',
        onClick: async input => {
          const resp = await handleExport({order_ids: [input.current.order.id]});
          if (resp.success) {
            window.location.assign(resp?.data?.file);
          }
        },
        isShow: input => input.current.order?.status !== 'draft',
        loading: exporting
      }
    ],
    Content: ({input}) => {
      const orderData = orders.find(order => order.id === input.current.order?.id) || {};
      const isCreate = input.current?.type === 'create';
      return (
        <>
          <Form
            {...{
              order: isCreate ? input.current.order : orderData,
              type: input.current?.type || 'create',
              onSuccess: () => {
                invalidateQueries({
                  ...(['view', 'create'].includes(input.current?.type)
                    ? {
                        queryKeys: [['purchase-order/base'], ['purchase-order']],
                        validateCurrent: false
                      }
                    : {})
                });
                closeSheet();
                store.dispatch(setToast({content: 'Success!'}));
              },
              onClose: closeSheet
            }}
          />
          {duplicateModal}
        </>
      );
    },
    size: 'large'
  });

  const {modal: deleteModal, openModal: openDeleteModal} = useConfirmModal({
    confirmAction: handleDelete,
    successCallback: () => {
      store.dispatch(setToast({content: 'Success!'}));
      invalidateQueries();
    }
  });

  const loading = isFetching;

  const togglePopover = orderId => setActivePopover(activePopover === orderId ? null : orderId);

  const rows = orders.flatMap(order => {
    const {id, origin_id, seller, store, items = [], created_at, status} = order;
    const itemIds = items.map(item => item.id);
    const allItemsSelected = itemIds.every(id => selectedItems.includes(id));
    const someItemsSelected = itemIds.some(id => selectedItems.includes(id));
    const statusData = findPOStatus(status);

    const totalItems = items.reduce((acc, item) => acc + item.qty, 0);
    const orderRow = [
      <div style={{display: 'flex', alignItems: 'center', gap: '.5rem'}}>
        <Button plain onClick={() => toggleActiveChild(id)}>
          <Icon backdrop source={isActiveChild(id) ? MinusMinor : PlusMinor} />
        </Button>
        <Checkbox
          disabled={loading}
          checked={allItemsSelected || (!allItemsSelected && someItemsSelected && 'indeterminate')}
          onChange={() => {
            handleOrderCheckboxChange(items);
            // toggleActiveChild(id);
          }}
        />
        {origin_id}
      </div>,
      !!statusData ? (
        <Badge status={statusData?.badge?.status}>{upperFirst(statusData?.content || '')}</Badge>
      ) : null,
      seller.first_name + ' ' + seller.last_name,
      totalItems,
      store.name,
      formatBothDateTime(created_at),
      <div style={{display: 'flex', justifyContent: 'flex-end'}}>
        <Popover
          active={activePopover === id}
          activator={<Button plain icon={HorizontalDotsMinor} onClick={() => togglePopover(id)} />}
          onClose={() => togglePopover(id)}
        >
          <ActionList
            items={[
              {
                disabled: loading,
                content: 'View',
                onAction: () => {
                  openOrderSheet({
                    order: {
                      ...order,
                      full_origin_id: order.origin_id,
                      ...splitOrderId(order.origin_id)
                    },
                    type: 'view'
                  });
                  togglePopover(id);
                },
                icon: ViewMinor
              },
              ...(order.status === 'draft'
                ? [
                    {
                      disabled: loading,
                      content: 'Edit',
                      onAction: () => {
                        openOrderSheet({
                          order: {
                            ...order,
                            full_origin_id: order.origin_id,
                            ...splitOrderId(order.origin_id)
                          },
                          type: 'edit'
                        });
                        togglePopover(id);
                      },
                      icon: EditMinor
                    }
                  ]
                : []),
              {
                disabled: loading,
                content: 'Delete',
                onAction: () => {
                  togglePopover(id);
                  openDeleteModal({order_ids: [id], is_delete: 1});
                },
                destructive: true,
                icon: DeleteMinor
              }
            ]}
          />
        </Popover>
      </div>
    ];

    function ChildItemComponent() {
      const columns = ['', 'Item', 'Product type', 'Boxes', 'Shipping', 'Tracking'];
      return (
        <div className="HCC-Grid__ChildItems">
          <Container fluid>
            <Row>
              {columns.map((column, index) => (
                <Col
                  key={index}
                  {...{
                    ...(index === 0 ? {xs: 1} : {}),
                    style: {
                      padding: '1rem',
                      fontWeight: '500',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'start',
                      ...(index !== 0 && {backgroundColor: 'rgb(255, 217, 192)'})
                    }
                  }}
                >
                  {column}
                </Col>
              ))}
            </Row>

            {items.map((item, i) => {
              return (
                <Row
                  style={{
                    padding: '1rem',
                    ...(i < items.length - 1 && {borderBottom: '1px solid #e0e0e0'}),
                    background: 'var(--p-surface-pressed)'
                  }}
                  key={i + '_item'}
                >
                  <Col xs={1}>
                    <Checkbox
                      disabled={loading}
                      checked={selectedItems.includes(item.id)}
                      onChange={() => handleItemCheckboxChange(item.id)}
                    />
                  </Col>
                  <Col>
                    <div>
                      <TextStyle variation="strong">{item.product_title}</TextStyle>
                      <p>
                        <TextStyle variation="subdued">SKU: {item.product_variant_sku}</TextStyle>
                      </p>
                    </div>
                  </Col>
                  <Col>{item?.product_type?.title || ''}</Col>

                  <Col>{item.qty}</Col>
                  <Col>{upperFirst(item?.shipping || '')}</Col>
                  <Col>track</Col>
                </Row>
              );
            })}
          </Container>
        </div>
      );
    }

    return [orderRow, isActiveChild(id) ? [<ChildItemComponent />] : []];
  });

  const loadingComp = [Array.from({length: 7}, (_, i) => <SkeletonBodyText lines={5} />)];

  const allItemIds = orders.flatMap(order => order.items.map(item => item.id));
  const allSelected = selectedItems.length === allItemIds.length && selectedItems.length > 0;
  const someSelected = selectedItems.length > 0 && selectedItems.length < allItemIds.length;

  return (
    <>
      <Page
        title="Purchase order"
        fullWidth
        primaryAction={{
          loading,
          disabled: loading,
          content: 'Create purchase order',
          onAction: () =>
            openOrderSheet({
              order: {
                create_as_draft: 1,
                customer_address: {
                  first_name: 'HC',
                  last_name: 'HC',
                  city: 'HC',
                  postal_code: '10000',
                  address: 'HC',
                  country: 'HC'
                },
                customer_email: 'purchase-order@hc.com',
                stock_order: 1
              },
              type: 'create'
            })
        }}
      >
        <Card>
          <Tabs
            tabs={purchaseOrderTabs.map(tab => {
              const count = orderCounts[tab.id] || 0;

              return {
                ...tab,
                content: (
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '1rem',
                      justifyContent: 'center'
                    }}
                  >
                    <p>{tab.content}</p>
                    <Badge status={tab.badge.status}>
                      {baseDataLoading ? (
                        <SpinnerCustom styles={{width: '1.3rem', height: '1.3rem'}} />
                      ) : (
                        count
                      )}
                    </Badge>
                  </div>
                )
              };
            })}
            selected={selectedTab}
            onSelect={tabIndex => {
              if (loading) return;
              setParams(prev => ({...prev, status: purchaseOrderTabs[tabIndex].id, page: 1}));
              handleTabChange(tabIndex);
            }}
            fitted
          >
            <Card.Section>
              <Filters
                disabled={loading}
                queryValue={queryValue}
                queryPlaceholder="Search orders"
                filters={[]}
                onQueryChange={handleQueryChange}
                onQueryClear={() => {
                  setQueryValue('');
                  onSearchOrder('');
                }}
              />
            </Card.Section>
            <Card.Section flush>
              <DataTable
                columnContentTypes={[]}
                footerContent={
                  <Stack distribution="equalSpacing" alignment="center">
                    {!isFetching && <Stack.Item>Total: {total} orders</Stack.Item>}
                    <Stack.Item fill />
                    <Stack.Item>
                      <Pagination
                        label={isFetching ? <SpinnerCustom /> : `Page: ${cur_page}`}
                        loading={true}
                        hasNext={!isFetching && cur_page * per_page < total}
                        hasPrevious={!isFetching && cur_page > 1}
                        onNext={() => handleParamsChange('page', cur_page + 1)}
                        onPrevious={() => handleParamsChange('page', cur_page - 1)}
                      />
                    </Stack.Item>
                  </Stack>
                }
                headings={[
                  <div style={{display: 'flex', alignItems: 'center'}}>
                    <Checkbox
                      disabled={loading}
                      checked={allSelected ? true : !allSelected && someSelected && 'indeterminate'}
                      onChange={handleSelectAllChange}
                    />
                    Order ID
                  </div>,
                  'Status',
                  'Seller',
                  'Total Items',
                  'Store',
                  'Date',
                  <div style={{textAlign: 'right'}}>Actions</div>
                ]}
                rows={loading ? loadingComp : rows}
              />
            </Card.Section>
          </Tabs>
        </Card>
      </Page>
      {deleteModal}
      {orderFormSheet}
    </>
  );
};

export default OrderPurchase;
