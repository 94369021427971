import {cloneDeep} from 'lodash';

export default function updateNestedValue(obj, path, value) {
  const keys = path.split('.');

  obj = cloneDeep(obj);

  keys.slice(0, -1).reduce((acc, key) => {
    return (acc[key] = acc[key] || {});
  }, obj)[keys[keys.length - 1]] = value;

  return obj;
}
