export default function setWindow(key, value) {
  if (!window.HC_APP) window.HC_APP = {};

  const keys = key.split('.');

  let current = window.HC_APP;

  for (let i = 0; i < keys.length - 1; i++) {
    if (!current[keys[i]]) {
      current[keys[i]] = {};
    }
    current = current[keys[i]];
  }

  current[keys[keys.length - 1]] = value;
}

export function getWindow(key, defaultValue = undefined) {
  if (!window.HC_APP) return defaultValue;

  const keys = key.split('.');
  let current = window.HC_APP;

  for (let i = 0; i < keys.length; i++) {
    if (current && current.hasOwnProperty(keys[i])) {
      current = current[keys[i]];
    } else {
      return defaultValue;
    }
  }

  return current;
}
