import SpinnerCustom from '@assets/components/SpinnerCustom/SpinnerCustom';
import compareIdCounter from '@assets/helpers/utils/compareIdCounter';
import {useHttpReq} from '@assets/hooks/api/useHttpReq';
import {debounce} from '@assets/hooks/useDebounce';
import useInput from '@assets/hooks/useInput';
import noImage from '@assets/resources/no-image.jpg';
import {
  Button,
  Checkbox,
  Collapsible,
  Icon,
  Layout,
  Pagination,
  ResourceItem,
  ResourceList,
  Scrollable,
  Stack,
  TextField,
  TextStyle,
  Thumbnail
} from '@shopify/polaris';
import {
  ArrowRightMinor,
  ChevronDownMinor,
  ChevronRightMinor,
  SearchMinor
} from '@shopify/polaris-icons';
import {useCallback, useEffect, useState} from 'react';

export default function SelectProducts({input, order = {}}) {
  const isEdit = !!order?.id;
  const {seller_id, store_id} = order;
  const [params, handleChangeParams] = useInput({
    only_stock: true,
    limit: 10,
    page: 1,
    seller_ids: seller_id,
    sale_channel_ids: store_id
  });

  const [items, setItems] = useState(order?.items || []);
  const [activeChild, setActiveChild] = useState(null);
  const [searchValue, setSearchValue] = useState(params?.search_value || '');

  const {data: {data: products = [], ...pageInfo} = {}, isLoading: loading} = useHttpReq({
    queryKey: ['products', params],
    requestOptions: {
      url: '/product/index',
      params
    }
  });

  const handleProductCheck = product => {
    const {id, product_variants} = product;

    setItems(prev => {
      if (!!isProductChecked(product)) return prev.filter(x => x.product_id !== id);

      const newItems = product_variants
        .map(variant => {
          const {product_type_variant, product} = getProductTypeVariant(id, variant);

          return {
            ...variant,
            quantity: 1,
            product_type_variant,
            product_title: product.title,
            synced: false
          };
        })
        .filter(variant => !isVariantChecked(id, variant, prev));
      return [...prev, ...newItems];
    });
  };

  const isProductChecked = product => {
    const checkedLength = items.filter(x => x.product_id === product.id).length;

    return checkedLength
      ? checkedLength === product.product_variants.length || 'indeterminate'
      : false;
  };

  const compareVariant = (variant, item) => {
    return !!compareIdCounter(
      item,
      variant,
      ...(isEdit ? ['product_type_variant_id', 'product_id'] : [])
    );
  };

  const isVariantChecked = (productId, variant, list = items) => {
    return !!list.find(x => x.product_id === productId && compareVariant(x, variant));
  };
  const handleVariantCheck = (productId, variant) => {
    setItems(prev => {
      if (isVariantChecked(productId, variant, prev)) {
        return prev.filter(x => x.product_id !== productId || !compareVariant(x, variant));
      }

      const {product_type_variant, product} = getProductTypeVariant(productId, variant);

      return [
        ...prev,
        {...variant, quantity: 1, product_type_variant, product_title: product.title, synced: false}
      ];
    });
  };

  const getProductTypeVariant = (productId, variant) => {
    const product = products.find(x => x.id === productId);
    const product_type_variant = product?.product_types[0]?.product_type_variants.find(
      x => x.id === variant.product_type_variant_id
    );

    return {product_type_variant, product};
  };

  const toggleActiveChild = (productId, force = false) => {
    if (!event.target.className?.includes?.('Polaris-Checkbox__Input') || force) {
      setActiveChild(prev => (prev === productId ? null : productId));
    }
  };

  const isActiveChild = productId => {
    return activeChild === productId;
  };

  useEffect(() => {
    if (activeChild) {
      if (!isProductChecked({id: activeChild, product_variants: []})) {
        setActiveChild(null);
      }
    }

    input.current.items = items?.map(item => ({...item, item_sku: item.sku, qty: '1'}));
  }, [items]);

  const onSearch = useCallback(
    debounce(val => handleChangeParams('search_value', val), 700),
    []
  );

  return (
    <Layout>
      <Layout.Section>
        <TextField
          value={searchValue || ''}
          onChange={val => {
            setSearchValue(val);
            onSearch(val);
          }}
          placeholder="Search products"
          prefix={<Icon source={SearchMinor} />}
        />
      </Layout.Section>

      {loading ? (
        <Layout.Section>
          <SpinnerCustom isAnimation />
        </Layout.Section>
      ) : (
        <>
          <Layout.Section>
            <div style={{maxHeight: '50rem', overflow: 'auto'}}>
              <Scrollable>
                {!products.length && (
                  <div style={{paddingTop: '1rem'}}>
                    <span>No record found</span>
                  </div>
                )}

                <ResourceList
                  items={products}
                  renderItem={product => (
                    <ResourceItem
                      id={product.id}
                      verticalAlignment="center"
                      onClick={() => toggleActiveChild(product.id)}
                    >
                      <Stack alignment="center" spacing="extraTight">
                        <Checkbox
                          label=""
                          checked={isProductChecked(product)}
                          onChange={() => {
                            handleProductCheck(product);
                            if (activeChild !== product.id) {
                              toggleActiveChild(product.id, true);
                            }
                          }}
                        />
                        <Thumbnail source={product.mockups?.[0] || noImage} alt="" size="small" />
                        <Stack.Item fill>{product.title}</Stack.Item>
                        <Button
                          plain
                          icon={isActiveChild(product.id) ? ChevronDownMinor : ChevronRightMinor}
                        />
                      </Stack>
                      <Collapsible
                        open={isActiveChild(product.id)}
                        transition={{timingFunction: 'ease-in-out'}}
                      >
                        <ResourceList
                          items={product.product_variants.filter(variant => variant.status === 1)}
                          renderItem={variant => {
                            console.log(variant, product);

                            const productTypeVariant = product.product_types[0].product_type_variants?.find(
                              vr => vr.id === variant.product_type_variant_id
                            );

                            return (
                              <ResourceItem
                                id={variant.id}
                                verticalAlignment="center"
                                onClick={() => handleVariantCheck(product.id, variant)}
                              >
                                <Stack alignment="center" spacing="extraTight">
                                  <Stack.Item>
                                    <Checkbox
                                      label=""
                                      checked={isVariantChecked(product.id, variant)}
                                    />
                                  </Stack.Item>
                                  <Stack vertical spacing="extraTight">
                                    <Stack.Item fill>
                                      <TextStyle variation="warning">
                                        {productTypeVariant?.name}
                                      </TextStyle>
                                    </Stack.Item>
                                    {!!variant.variant_code && (
                                      <Stack.Item fill>
                                        <TextStyle variation="code">
                                          {variant.variant_code}
                                        </TextStyle>
                                      </Stack.Item>
                                    )}
                                  </Stack>
                                </Stack>
                              </ResourceItem>
                            );
                          }}
                        />
                      </Collapsible>
                    </ResourceItem>
                  )}
                />
              </Scrollable>
            </div>
          </Layout.Section>
          <Layout.Section>
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Pagination
                hasNext={pageInfo.per_page * pageInfo.cur_page < pageInfo.total}
                hasPrevious={pageInfo.cur_page > 1}
                onNext={() => handleChangeParams('page', pageInfo.cur_page + 1)}
                onPrevious={() => handleChangeParams('page', pageInfo.cur_page - 1)}
              />
            </div>
          </Layout.Section>
        </>
      )}
    </Layout>
  );
}
