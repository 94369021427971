import {AppProvider} from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import {QueryClient, QueryClientProvider} from '@tanstack/react-query';
import {connect} from 'react-redux';
import {Router} from 'react-router-dom';
import ReactRouterLink from './components/ReactRouterLink';
import theme from './config/theme';
import {history} from './helpers';
import setWindow from './helpers/windowHelper';
import Routes from './routes/routes';
import AppLayout from './components/AppLayout';

function App({user}) {
  const queryClient = new QueryClient();
  setWindow('user', user);

  return (
    <AppProvider i18n={translations} theme={theme} linkComponent={ReactRouterLink}>
      <QueryClientProvider client={queryClient}>
        <Router history={history}>
          <AppLayout>
            <Routes />
          </AppLayout>
        </Router>
      </QueryClientProvider>
    </AppProvider>
  );
}

export default connect(({user}) => ({user}), null)(App);
