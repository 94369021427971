import Order from '@assets/loadables/Order';
import OrderDraft from '@assets/loadables/OrderDraft';
import OrderLogs from '@assets/loadables/OrderLogs';
import OrderPurchase from '../pages/Order/OrderPurchase/OrderPurchase';

const orderRoutes = [
  {
    path: '/orders',
    component: Order,
    scope: 'orders.view'
  },
  {
    path: '/orders/draft',
    component: OrderDraft,
    scope: 'orders.view'
  },
  {
    path: '/orders/logs',
    component: OrderLogs,
    scope: ''
  },
  {
    path: '/orders/purchase',
    component: OrderPurchase,
    scope: 'orders.purchase.view'
  }
];

export default orderRoutes;
