import {setToast} from '@assets/actions/layout/setToastAction';
import {store} from '@assets/index';
import Http from '@assets/services/Http';
import {useState} from 'react';
/**
 * @param url
 * @param defaultState
 * @param fullResp
 * @param useToast
 * @param successMsg
 * @param createErrorMessage
 * @returns {{editing: boolean, handleEdit}}
 */
export default function useEditApi({
  url,
  defaultState = false,
  createErrorMessage = 'Failed to save',
  token,
  fullResp = false
}) {
  const [editing, setEditing] = useState(defaultState);

  /**
   * @param data
   * @param newEditing
   * @returns {Promise<boolean>}
   */
  const handleEdit = async (data, newEditing = true) => {
    try {
      setEditing(prev =>
        typeof newEditing === 'boolean' ? newEditing : {...prev, [newEditing]: true}
      );
      const resp = await Http({url, data, method: 'PUT', token});
      const success = resp?.success || false;
      if (!success) {
        store.dispatch(setToast({content: resp.message, error: true}));
      }
      return fullResp ? resp : success;
    } catch (e) {
      console.log(e);
      store.dispatch(setToast({content: createErrorMessage, error: true}));
      return false;
    } finally {
      setEditing(prev =>
        typeof newEditing === 'boolean' ? !newEditing : {...prev, [newEditing]: false}
      );
    }
  };

  return {editing, handleEdit};
}
