import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';

export default function SpinnerCustom({styles = {}, wrapperStyles = {}, isAnimation = false}) {
  if (isAnimation) return <span className="Mageplaza__Loading--animloader" />;

  return (
    <div className="Mageplaza__LoadingWrapper" style={wrapperStyles}>
      <span className="Mageplaza__Loading--spinner" style={styles} />
    </div>
  );
}

SpinnerCustom.propTypes = {
  styles: PropTypes.object,
  wrapperStyles: PropTypes.object,
  isAnimation: PropTypes.bool
};
