import {createRoot} from 'react-dom/client';
import {Provider} from 'react-redux';
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';
import App from './App';
import './index.css';
import createStore from './reducers/createStore';
import './styles/app.scss';

export const store = createStore(history);
(async () => {
  const persistor = persistStore(store);
  const container = document.getElementById('root');
  const root = createRoot(container);

  root.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  );
})();
