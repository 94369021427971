import React, {useEffect, useState} from 'react';
import {TextField} from '@shopify/polaris';

export default function TextFieldMask({
  label = '',
  value = '',
  onChange,
  lostFocus,
  placeholder = '',
  requiredIndicator = false,
  disabled = false,
  readOnly = false,
  error = null,
  min = null,
  type = 'text',
  prefix = '',
  suffix = '',
  maxWidth = null,
  maxLength = '',
  styles = {}
}) {
  const [input, setInput] = useState(value);

  useEffect(() => {
    const number = parseFloat(input);
    // update value when input is changed by (up/down) arrow
    if (
      type === 'number' &&
      !isNaN(number) &&
      [number + 1, number - 1].includes(parseFloat(value || '0'))
    ) {
      onChange(input);
    }
  }, [input]);

  useEffect(() => {
    if (value !== input) setInput(value);
  }, [value]);

  const onBlur = () => {
    if (value !== input) onChange(typeof input === 'string' ? input.trim() : input);
    if (lostFocus) {
      lostFocus();
    }
  };

  const field = (
    <div style={styles}>
      <TextField
        requiredIndicator={requiredIndicator}
        label={label}
        value={input}
        onChange={setInput}
        onBlur={onBlur}
        prefix={prefix}
        suffix={suffix}
        disabled={disabled}
        readOnly={readOnly}
        placeholder={placeholder}
        error={error}
        type={type}
        min={min}
        autoComplete="off"
        maxLength={maxLength}
      />
    </div>
  );

  return maxWidth ? <div style={{maxWidth}}>{field}</div> : field;
}
