/**
 * Tách order ID và origin ID từ một chuỗi origin ID đầy đủ
 * Ví dụ: "2135-BBB-rewre-343" => { orderId: "2135", originId: "BBB-rewre-343" }
 *
 * @param {string} fullOriginId - Chuỗi origin ID đầy đủ (VD: "2135-BBB-rewre-343")
 * @returns {{orderId: string, originId: string}} Object chứa order ID và origin ID
 */
export function splitOrderId(fullOriginId) {
  if (!fullOriginId) return {origin_id: '', origin_id: ''};

  const [order_id, ...originParts] = fullOriginId.split('-');
  return {
    order_id,
    origin_id: originParts.join('-')
  };
}

/**
 * Kiểm tra xem một chuỗi có phải là origin ID hợp lệ hay không
 * Format hợp lệ: "{number}-{string}-{string}-{string}"
 *
 * @param {string} fullOriginId - Chuỗi cần kiểm tra
 * @returns {boolean} true nếu là origin ID hợp lệ
 */
export function isValidOriginId(fullOriginId) {
  if (!fullOriginId) return false;

  const [orderId, ...originParts] = fullOriginId.split('-');

  // Kiểm tra orderId phải là số
  if (isNaN(orderId)) return false;

  // Kiểm tra phải có ít nhất 1 phần origin ID
  if (originParts.length === 0) return false;

  return true;
}
