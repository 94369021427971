import {useDeferredValue, useEffect, useState} from 'react';

function useDebounce(value, delay) {
  const valueDeferred = useDeferredValue(value);
  const [debounceValue, setDebounceValue] = useState(value);

  useEffect(() => {
    const delaySearch = setTimeout(() => {
      setDebounceValue(valueDeferred);
    }, delay);

    return () => clearTimeout(delaySearch);
  }, [valueDeferred]);

  return debounceValue;
}

export default useDebounce;

export function debounce(func, ms = 1000) {
  let timer;
  return function(...args) {
    // eslint-disable-next-line no-invalid-this
    const context = this;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, ms);
  };
}
