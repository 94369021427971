import {setToast} from '@assets/actions/layout/setToastAction';
import {formatDateOnly, formatTimeOnly} from '@assets/helpers/utils/formatFullTime';
import useCreateApi from '@assets/hooks/api/useCreateApi';
import {useInvalidateCache} from '@assets/hooks/api/useHttpReq';
import useInput from '@assets/hooks/useInput';
import {store} from '@assets/index';
import {
  Button,
  FormLayout,
  Heading,
  InlineError,
  List,
  Spinner,
  Stack,
  TextStyle
} from '@shopify/polaris';
import {useState} from 'react';

export default function Timeline({order, onChange = () => {}, canPost = false}) {
  const invalidateCache = useInvalidateCache();
  const [isError, setIsError] = useState(false);
  const [comment, onCommentChange] = useInput({
    order_id: order.id?.toString() || '',
    visible_seller: '1',
    comment: ''
  });

  const {creating: postingComment, handleCreate: handlePost} = useCreateApi({
    url: '/orders/addLog',
    fullResp: true
  });

  const onPost = async () => {
    if (!order?.id) {
      onCommentChange('comment', '');
      onChange('time_lines', [...(order.time_lines || []), comment]);
      return;
    }
    const {data, success} = await handlePost(comment);
    if (success) {
      onCommentChange('comment', '');
      store.dispatch(setToast({content: 'Post comment successfully'}));
      invalidateCache({queryKeys: [['purchase-order']]});
    }
  };

  const {time_lines = [], created_at} = order;
  const createLog = {
    created_at,
    content: 'Order was created.'
  };
  const timeLines = !time_lines.length
    ? created_at
      ? [createLog]
      : []
    : canPost
    ? [...time_lines]
    : [createLog, ...time_lines];
  const days = [...new Set(timeLines.map(log => formatDateOnly(log.created_at)))];
  const prepareContent = log => {
    const {action, comment, status, order_item_sku, content} = log;
    const labels = [];
    if (content) {
      return [content];
    }
    if (comment) {
      labels.push(
        <>
          <span style={{fontWeight: 'bolder'}}>Comment</span>:{' '}
          <div dangerouslySetInnerHTML={{__html: comment}} />
        </>
      );
    }
    if (status) {
      labels.push(
        <>
          {'Order was marked as '}
          <TextStyle variation="strong">
            {capitalizeFirstLetter(status?.split('_').join(' '))}
          </TextStyle>
          .
        </>
      );
    }
    if (order_item_sku) {
      labels.push(<>Item SKU: {order_item_sku}</>);
    }
    if (action && action !== 'comment') {
      labels.push(<>Action: {action}</>);
    }
    return labels;
  };

  return (
    <FormLayout>
      <div className={`HCC-Timeline_Body ${!!days.length ? 'has-timeline' : ''}`}>
        {canPost && (
          <>
            <div className="HCC-Timeline_Body_Svg">{svg}</div>
            <Stack>
              <p className="HCC-Timeline_Comment__Title">Comment</p>
              {isError && <InlineError message={'Comment cannot be empty'} />}
            </Stack>
            <div className="HCC-Timeline_Comment">
              <textarea
                value={comment.comment}
                onChange={e => {
                  if (e.target.value.length > 0) {
                    setIsError(false);
                  }
                  onCommentChange('comment', e.target.value);
                }}
                className="HCC-Timeline_Comment--textarea"
                type="text"
                disabled={postingComment}
              ></textarea>
              <div className="HCC-Timeline_ActionWrapper">
                <Button
                  disabled={postingComment}
                  onClick={() => {
                    if (comment.comment.length === 0) {
                      setIsError(true);
                      return;
                    }
                    onPost();
                  }}
                >
                  {postingComment ? <Spinner size="small" /> : 'Post'}
                </Button>
              </div>
            </div>
          </>
        )}
        {days.map((day, index) => (
          <div className="HCC-Timeline_Item" key={index}>
            <div className="HCC-Timeline_Content">
              <Heading>{day}</Heading>
              <List type="bullet">
                {timeLines
                  .filter(log => formatDateOnly(log.created_at) === day)
                  .map((log, childIndex) => (
                    <List.Item key={childIndex}>
                      <div className="HCC-Timeline_Row">
                        <Stack vertical spacing="extraTight">
                          {prepareContent(log).map((item, index) => (
                            <Stack.Item key={index}>{item}</Stack.Item>
                          ))}
                        </Stack>
                        <TextStyle variation="subdued">{formatTimeOnly(log.created_at)}</TextStyle>
                      </div>
                    </List.Item>
                  ))}
              </List>
            </div>
          </div>
        ))}
      </div>
    </FormLayout>
  );
}

const svg = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1423_12149)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0C24.8224 0 32 7.1776 32 16C32 24.8224 24.8224 32 16 32C7.1776 32 0 24.8224 0 16C0 7.1776 7.1776 0 16 0ZM25.984 24C24.7863 25.4976 23.2671 26.7066 21.5389 27.5374C19.8106 28.3682 17.9176 28.7996 16 28.7996C14.0824 28.7996 12.1894 28.3682 10.4611 27.5374C8.73287 26.7066 7.21365 25.4976 6.016 24C7.21365 22.5024 8.73287 21.2934 10.4611 20.4626C12.1894 19.6318 14.0824 19.2004 16 19.2004C17.9176 19.2004 19.8106 19.6318 21.5389 20.4626C23.2671 21.2934 24.7863 22.5024 25.984 24ZM16 16C16.6303 16 17.2545 15.8758 17.8369 15.6346C18.4192 15.3934 18.9484 15.0398 19.3941 14.5941C19.8398 14.1484 20.1934 13.6192 20.4346 13.0369C20.6758 12.4545 20.8 11.8303 20.8 11.2C20.8 10.5697 20.6758 9.94548 20.4346 9.36312C20.1934 8.78076 19.8398 8.25161 19.3941 7.80589C18.9484 7.36017 18.4192 7.0066 17.8369 6.76538C17.2545 6.52416 16.6303 6.4 16 6.4C14.727 6.4 13.5061 6.90571 12.6059 7.80589C11.7057 8.70606 11.2 9.92696 11.2 11.2C11.2 12.473 11.7057 13.6939 12.6059 14.5941C13.5061 15.4943 14.727 16 16 16Z"
        fill="#E85D04"
      />
    </g>
    <defs>
      <clipPath id="clip0_1423_12149">
        <rect width="32" height="32" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
