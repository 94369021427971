import {createBrowserHistory} from 'history';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import {appConfig} from '@assets/config/app';

firebase.initializeApp(appConfig);
export const storage = firebase.storage();
export const history = createBrowserHistory();

export function getUserToken(user = {}) {
  return `Bearer ${user?.meta?.access_token}`;
}
