export const purchaseOrderRules = data => ({
  origin_id: {
    value: data.origin_id,
    validation: 'required'
  },
  seller_id: {
    value: data.seller_id,
    validation: 'required'
  },
  store_id: {
    value: data.store_id,
    validation: 'required'
  },
  items: {
    value: data.items,
    validation: 'custom',
    customHandler: value => {
      return value?.length;
    }
  },
  ...(data?.items?.length > 0 && {
    shippings: {
      value: data.items,
      valueIds: data.items.map(item => item.id),
      validation: 'custom_array',
      customHandler: (items, index) => {
        const item = items[index];
        return !!item.shipping;
      }
    },
    wareHouses: {
      value: data.items,
      valueIds: data.items.map(item => item.id),
      validation: 'custom_array',
      customHandler: (items, index) => {
        const item = items[index];
        return !!item.warehouse;
      }
    }
  })
});
