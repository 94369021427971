import AutoCompleteCustom from '@assets/components/AutoCompleteCustom/AutoCompleteCustom';
import SheetBody from '@assets/components/Sheet/SheetBody';
import OptionListSelect from '@assets/components/Utils/OptionListSelect';
import TextFieldMask from '@assets/components/Utils/TextFieldMask';
import {purchaseOrderRules} from '@assets/config/validations/purchaseOrderRules';
import isEmpty from '@assets/helpers/utils/isEmpty';
import useCreateApi from '@assets/hooks/api/useCreateApi';
import useEditApi from '@assets/hooks/api/useEditApi';
import {useHttpReq} from '@assets/hooks/api/useHttpReq';
import useConfirmModal from '@assets/hooks/modal/useConfirmModal';
import useFormValidation from '@assets/hooks/service/useValidation';
import useInput from '@assets/hooks/useInput';
import noImage from '@assets/resources/no-image.jpg';
import {
  Button,
  Card,
  IndexTable,
  InlineError,
  Labelled,
  Layout,
  RadioButton,
  Select,
  Stack,
  TextField,
  Thumbnail
} from '@shopify/polaris';
import {CirclePlusMinor, DeleteMinor} from '@shopify/polaris-icons';
import SelectProducts from './SelectProducts';
import Timeline from './Timeline';
import {purchaseOrderTabs} from '@assets/config/purchaseOrder';
import usePopActionList from '@assets/hooks/grid/usePopActionList';

const {Cell, Row} = IndexTable;

export default function Form({
  order: orderData = {},
  onSuccess = () => {},
  type = 'create',
  onClose = () => {}
}) {
  const [order, onOrderChange] = useInput(orderData);
  const {validations, handleValidateForm} = useFormValidation(purchaseOrderRules(order));
  const {PopAction, togglePop} = usePopActionList({mouseOutEvent: false});

  console.log(order, 'order data');

  const {handleCreate, creating} = useCreateApi({url: 'orders/create'});
  const {handleEdit, editing} = useEditApi({url: 'orders/updateOrder'});

  const onChange = (key, value) => {
    onOrderChange(key, value);
    if (!isEmpty(validations)) handleValidateForm();
  };

  const isView = type === 'view';
  const isEdit = type === 'edit';
  const isCreate = type === 'create';

  const {data: {data: orderContext} = {}} = useHttpReq({
    queryKey: ['purchase-order/base'],
    requestOptions: {
      url: '/purchase-order/base',
      params: {
        is_purchase_order: true
      }
    },
    useSuspense: true
  });

  const onSave = async (statusChange = false) => {
    if (!handleValidateForm()) return;

    if (statusChange) {
      await handleEdit({...order, order_id: order.id, status: statusChange});
      onSuccess();
      return;
    }

    let status = false;
    switch (type) {
      case 'create':
        status = await handleCreate({...order, add_items: order.items});

        break;
      case 'edit':
        status = await handleEdit({...order, order_id: order.id});

        break;
      case 'view':
        status = await handleEdit({...order, order_id: order.id, status: 'created'});

        break;
    }
    if (status) onSuccess();
  };

  const handleItemOperation = (operation, params) => {
    if (isCreate) {
      if (operation === 'add') {
        const {items} = params;
        onChange('items', items);
      }
      return;
    }

    if (operation === 'add') {
      const {items} = params;
      onChange('items', items);
      onChange(
        'add_items',
        items.filter(({synced = true}) => !synced)
      );
    } else if (operation === 'remove') {
      const {variant} = params;
      const {id: variantId, synced = true} = variant;
      const updateItems = order?.update_items || [];
      const addItems = order?.add_items || [];

      if (synced) {
        onChange('delete_items', [...(order?.delete_items || []), variantId]);
      }

      onChange(
        'update_items',
        updateItems.filter(item => item.id !== variantId)
      );
      onChange(
        'add_items',
        addItems.filter(item => item.id !== variantId)
      );
    } else if (operation === 'update') {
      const {index, key, value, variant} = params;
      const {id: variantId, synced = true} = variant;
      const updateKey = synced ? 'update_items' : 'add_items';
      const itemsUpdate = order?.[updateKey] || [];
      const isExists = itemsUpdate.find(item => item.id === variantId) || false;

      onChange(
        updateKey,
        (isExists
          ? itemsUpdate.map(item => (item.id == variantId ? {...item, [key]: value} : item))
          : [...itemsUpdate, {...order.items[index], [key]: value}]
        ).map(({status, ...item}) => ({...item, item_id: item.id}))
      );
    }
  };

  const {modal: selectProductModal, openModal: openSelectProductModal} = useConfirmModal({
    HtmlTitle: () => `Add products`,
    large: false,
    HtmlContent: ({input}) => <SelectProducts {...{input, order}} />,
    confirmAction: async ({items = []}) => {
      handleItemOperation('add', {items});
      return true;
    }
  });

  const {first_name = null, last_name = null, email = null} =
    orderContext.sellers.find(s => s.id === +order?.seller_id) || {};

  const {name: storeName = ''} = orderContext.stores.find(s => s.id === +order?.store_id) || {};

  return (
    <SheetBody
      moreActions={[
        {
          label: 'Close',
          onClick: onClose,
          loading: creating || editing
        },
        ...(!order?.id || order?.status === 'draft'
          ? [
              {
                label: isView ? 'Publish' : `Save${isCreate ? ' as draft' : ''}`,
                primary: true,
                onClick: onSave,
                loading: creating || editing
              }
            ]
          : []),
        ...(order?.status !== 'draft' && !isCreate
          ? [
              {
                children: (
                  <PopAction
                    id={'change-status'}
                    key={'change-status'}
                    sectioned={true}
                    mouseOutEvent={false}
                    activator={
                      <Button
                        primary
                        disclosure
                        onClick={() => togglePop('change-status')}
                        loading={editing}
                      >
                        Change status
                      </Button>
                    }
                  >
                    <Stack vertical>
                      {purchaseOrderTabs.map(({id, content}, index) => {
                        return (
                          <Button
                            key={index}
                            primary={id === order?.status}
                            disabled={editing || id === order?.status}
                            onClick={() => {
                              onOrderChange('status', id);
                              onSave(id);
                            }}
                          >
                            {content}
                          </Button>
                        );
                      })}
                    </Stack>
                  </PopAction>
                )
              }
            ]
          : [])
      ]}
    >
      <Layout>
        <Layout.Section>
          <Layout>
            <Layout.Section oneThird>
              <Card>
                <Card.Section>
                  <Labelled id="" label="Origin ID" requiredIndicator>
                    {isCreate && !order.id ? (
                      <TextField
                        readOnly={!(isCreate && !order.id)}
                        label="Origin ID"
                        labelHidden
                        value={order?.origin_id || ''}
                        onChange={val => onChange('origin_id', val)}
                        requiredIndicator
                        error={validations?.origin_id?.message}
                      />
                    ) : (
                      <strong>{order?.origin_id}</strong>
                    )}
                  </Labelled>
                </Card.Section>
              </Card>
            </Layout.Section>
            <Layout.Section oneThird>
              <Card>
                <Card.Section>
                  <Labelled
                    id=""
                    label="Seller"
                    requiredIndicator
                    error={validations?.seller_id?.message}
                  >
                    {isCreate && !order.id ? (
                      <OptionListSelect
                        disabled={!(isCreate && !order.id)}
                        input={order?.seller_id?.toString() || ''}
                        onChange={val => {
                          onChange('seller_id', val);
                          onChange('items', []);
                        }}
                        list={orderContext.sellers.map(s => ({
                          label: `${s.first_name} ${s.last_name}`,
                          value: s.id.toString(),
                          subTitle: s.email
                        }))}
                        error={validations?.seller_id?.message}
                        removeBtn={false}
                      />
                    ) : (
                      <strong>
                        {first_name} {last_name} - {email}
                      </strong>
                    )}
                  </Labelled>
                </Card.Section>
              </Card>
            </Layout.Section>
            <Layout.Section oneThird>
              <Card>
                <Card.Section>
                  <Labelled
                    id=""
                    label="Store"
                    requiredIndicator
                    error={validations?.store_id?.message}
                  >
                    {isCreate && !order.id ? (
                      <OptionListSelect
                        disabled={!order?.seller_id || !(isCreate && !order.id)}
                        input={order?.store_id?.toString() || ''}
                        onChange={val => {
                          onChange('store_id', val);
                          onChange('items', []);
                        }}
                        list={orderContext.stores
                          .filter(s => +s.seller_id === +(order?.seller_id || '0'))
                          ?.map(s => ({
                            label: s.name,
                            value: s.id.toString()
                          }))}
                        error={validations?.store_id?.message}
                        removeBtn={false}
                      />
                    ) : (
                      <strong>{storeName}</strong>
                    )}
                  </Labelled>
                </Card.Section>
              </Card>
            </Layout.Section>
          </Layout>
        </Layout.Section>
        <Layout.Section>
          <Card title={'Products'}>
            <AutoCompleteCustom
              data={order?.items || []}
              disabled={!order?.seller_id || !order?.store_id || isView}
              HtmlContent={({...props}) => {
                const {
                  onRemove: handleRemove = () => {},
                  onChange: handleChange = () => {}
                } = props;
                return (
                  <ProductList
                    {...{
                      ...props,
                      onRemove: (index, variant) => {
                        handleRemove(index);
                        if (!isCreate) {
                          handleItemOperation('remove', {index, variant});
                        }
                      },
                      onChange: (index, key, value, variant) => {
                        handleChange(index, key, value);
                        if (!isCreate) {
                          handleItemOperation('update', {index, key, value, variant});
                        }
                      },
                      orderContext,
                      isView,
                      validations
                    }}
                  />
                );
              }}
              defaultValue={{}}
              onChange={val => onChange('items', val)}
              actions={({disabled = false}) =>
                (!disabled || isCreate) && (
                  <Button
                    disabled={disabled}
                    monochrome
                    plain
                    removeUnderline
                    icon={CirclePlusMinor}
                    onClick={openSelectProductModal}
                  >
                    Add product variants
                  </Button>
                )
              }
            />
          </Card>
          <Card title="Timeline">
            <Card.Section>
              <Timeline
                {...{
                  order,
                  onChange,
                  canPost: true
                }}
              />
            </Card.Section>
          </Card>
        </Layout.Section>
        {selectProductModal}
      </Layout>
    </SheetBody>
  );
}

function ProductList({
  options: variants = [],
  orderContext: {warehouses = []} = {},
  onChange = () => {},
  onRemove = () => {},
  isView = false,
  validations
}) {
  const shippings = [
    {
      label: 'Shipping Sea',
      value: 'sea'
    },
    {
      label: 'Shipping Air',
      value: 'air'
    }
  ];

  return (
    <IndexTable
      itemCount={variants.length}
      selectable={false}
      headings={[
        {title: 'Product variant'},
        {title: 'SKU'},
        {title: 'Boxes'},
        {title: 'Units'},
        {title: 'Warehouse'},
        {title: 'Shipping'},
        {title: '', disabled: !!isView}
      ].filter(({disabled = false}) => !disabled)}
    >
      {variants.map((variant, index) => {
        const {
          product_type_variant = null,
          qty,
          mockups = [],
          warehouse = '',
          shipping = '',
          id
        } = variant;

        const invalidShipping = validations?.shippings?.[id];
        const invalidWarehouse = validations?.wareHouses?.[id];

        if (!product_type_variant) return null;

        const [title, sku] = [
          product_type_variant?.name || variant.product_title || variant.title,
          variant.product_variant_sku || variant.sku
        ];

        const {name: warehouseName = ''} = warehouses.find(wh => +wh.id === +warehouse) || {};
        const {label: shippingLabel = ''} = shippings.find(s => s.value === shipping) || {};

        return (
          <Row id={index + 'product'} key={index} selected={false} position={1}>
            <Cell>
              <div
                style={{
                  padding: '10px 0 ',
                  maxWidth: 'auto',
                  whiteSpace: 'wrap',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '.5rem'
                }}
              >
                <Thumbnail source={mockups?.[0] || noImage} alt="" size="medium" />
                {title}
              </div>
            </Cell>
            <Cell>{sku}</Cell>
            <Cell>
              {isView ? (
                qty
              ) : (
                <TextFieldMask
                  readOnly={isView}
                  styles={{maxWidth: '10rem', minWidth: '8rem'}}
                  value={qty?.toString() || '1'}
                  type="number"
                  min={1}
                  onChange={val => onChange(index, 'qty', val, variant)}
                />
              )}
            </Cell>
            <Cell>{+product_type_variant?.unit_per_box * +variant.qty}</Cell>
            <Cell>
              {isView ? (
                warehouseName
              ) : (
                <div style={{width: '13rem'}}>
                  <Select
                    disabled={isView}
                    value={warehouse ? parseInt(warehouse) : ''}
                    options={[
                      {label: 'Select...', value: '', disabled: true},
                      ...(warehouses?.map(w => ({label: w.name, value: w.id})) || [])
                    ]}
                    onChange={val => onChange(index, 'warehouse', val, variant)}
                    error={!warehouse ? invalidWarehouse?.message : null}
                  />
                </div>
              )}
            </Cell>
            <Cell>
              {isView ? (
                shippingLabel
              ) : (
                <div style={{width: '13rem'}}>
                  <Select
                    disabled={isView}
                    value={shipping || ''}
                    options={[{label: 'Select...', value: '', disabled: true}, ...shippings]}
                    onChange={val => onChange(index, 'shipping', val, variant)}
                    error={!shipping ? invalidShipping?.message : null}
                  />
                </div>
              )}
            </Cell>
            {!isView && (
              <Cell>
                <Button plain icon={DeleteMinor} onClick={() => onRemove(index, variant)} />
              </Cell>
            )}
          </Row>
        );
      })}
    </IndexTable>
  );
}
