export const purchaseOrderTabs = [
  {
    id: 'draft',
    content: 'Draft',
    badge: {content: 0, status: 'info'}
  },
  {
    id: 'created',
    content: 'Created',
    badge: {content: 0, status: 'success'}
  },
  {
    id: 'in_production',
    content: 'In production',
    badge: {content: 0, status: 'attention'}
  },
  {
    id: 'shipped',
    content: 'Shipped',
    badge: {content: 0, status: 'warning'}
  },
  {
    id: 'delivered',
    content: 'Delivered',
    badge: {content: 0, status: 'new'}
  },
  {
    id: 'cancelled',
    content: 'Cancelled',
    badge: {content: 0, status: 'critical'}
  }
];

export const findPOStatus = status => {
  return purchaseOrderTabs.find(tab => tab.id === status);
};
